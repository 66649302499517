// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';

@import '~ionic4-auto-complete/auto-complete';
@import './theme/mixins/breakpoints';

:root {
  --ion-toolbar-background: linear-gradient(180deg, #007237 0%, #00602e 100%);
  --ion-toolbar-color: #fff;
  --ion-font-family: 'Lato';
} // end :root

h1,
h2,
h3,
h4,
h5,
h6,
.card-content-md h1,
.card-content-md h2,
.card-content-md h3,
.card-content-md h4,
.card-content-md h5,
.card-content-md h6 {
  margin-top: 24px;
  margin-bottom: 24px;

  font-family: 'Roboto Slab', serif;
  text-align: center;

  color: #333333;
  opacity: 1;
} // end h1 h2 h3 h4 h5 h6

.card-content-md {
  h1 {
    font-size: 32px;
    font-weight: bold;
  } // end h1

  p {
    font-size: 16px;
  } // end p
} // end .card-content-md

.appWrapper {
  --padding-top: 100px;
  --padding-bottom: 100px;
  --background: none;
  background: url(./assets/images/bg-image-green.jpg) no-repeat center fixed;
  background-size: cover;

  ion-card {
    border-radius: 15px;
    padding: 0;

    @include md() {
      padding: 24px;
    }

    &.has-ribbon {
      position: relative;
      overflow: visible;
      contain: initial;

      margin-top: 60px;

      .ribbon {
        position: absolute;
        z-index: 9999999999;
        top: -40px;

        display: flex;
        align-items: center;

        @include xs($only: true) {
          left: -8px;
        } // end @sm

        @include lg() {
          top: -30px;
          left: -50px;
        } // end @lg

        &-icon {
          position: relative;
          z-index: 99;
          width: 80px;
          height: 80px;
        } // end &-icon

        &-text {
          position: relative;
          left: -12px;

          display: flex;
          align-items: center;

          height: 44px;
          padding-left: 18px;
          padding-right: 16px;

          background-color: #1f3686;
          box-shadow: 0 2px 2px 1px #1f3686;

          span {
            line-height: 1;
            text-align: left;
            color: #fff;
          } // end span

          &:after {
            display: block;
            content: '';

            position: absolute;
            right: -15px;
            top: 0px;

            height: 48px;
            width: 18px;
            background-image: url(/assets/images/ribbon-end.svg);
            background-repeat: no-repeat;
          }
        }
      } // end .ribbon
    } // end &.has-ribbon
  } // end ion-card
} // end .appWrapper

ion-button {
  --border-radius: 10px;
  text-transform: capitalize;
  letter-spacing: 0;

  .wrapFix {
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
  } // end .wrapFix

  &.button-large {
    --padding-top: 24px;
    --padding-bottom: 24px;

    height: 48px;
    font-size: 16px;
    font-weight: bold;
  } // end &.button-large

  &.button-solid {
    --box-shadow: none !important;
  } // end &.ion-button-solid
} // end ion-button

ion-header::after,
ion-footer::before {
  display: none !important;
}

ion-toolbar .lv-logo {
  height: 36px;
} // end ion-toolbar .lv-logo

.select-icon {
  top: -12px;
}

ion-item.item-select,
ion-item.item-input {
  --background: #f6f8f2 !important;
  --border-width: 0; // !important;
  --show-full-highlight: 0; // !important;

  border-radius: 10px;
  font-size: 18px !important;

  // & >>> ion-item >>>

  ion-label {
    margin-top: 14px;
    color: var(--ion-color-dark);
    font-weight: bold;

    z-index: 9999;
  } // end ion-label

  ion-input,
  ion-select {
    font-size: 18px;
  } // end ion-input

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f6f8f2 inset !important;
  } // end input:-webkit-autofill

  &.ion-touched {
    &.ion-invalid {
      --background: #fbe7e7 !important;
    } // end &.ion-invalid
  } // end &.ion-touched
} // end ion-item.item-input

ion-item.item-select ion-label {
  pointer-events: none;
} // end ion-item.item-select ion-label

.ion-color-primary-gradient {
  --ion-color-base: linear-gradient(to bottom, #0040d4 0%, #1b3b7c 100%);
  --ion-color-base-rgb: 0, 64, 212;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #0038bb;
  --ion-color-tint: #1a53d8;
}

.ion-color-green-gradient {
  --ion-color-base: linear-gradient(180deg, #007237 0%, #00602e 100%);
  --ion-color-base-rgb: 0, 114, 55;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #006430;
  --ion-color-tint: #1a804b;
}

.spacing-top {
  margin-top: 32px;

  &-sm {
    margin-top: 16px;
  } // end &-sm
} // end .margin-top

.spacing-bottom {
  margin-bottom: 32px;

  &-sm {
    margin-bottom: 16px;
  } // end &-sm
} // end .margin-bottom

ion-card {
  --background: #fff;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  @include transition();

  margin: 0;
  margin-top: 24px;

  text-align: center;

  p {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
  } // end p
} // end ion-card

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  transform-origin: center;
}

.loading {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 24px;
} // end .loading

.modal::part(content) {
  border-radius: 20px;

  ion-content {
    h1 {
      margin-top: 6px;
      font-weight: bold;
    }
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }
} // end .modal-editServices

.modal--full::part(content) {
  @media (min-width: 400px) {
    --min-height: 95%;
  } // end @media
} // end &--full
